import React, { useRef, useEffect, useState } from 'react'
import SubpageBanner from '../components/SubpageBanner';
import Cta from '../components/Cta';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import ServicesApi from "../api/Services"
import { Link } from "react-router-dom"
import { useTranslation } from 'react-i18next';
import Slider from "react-slick";

gsap.registerPlugin(ScrollTrigger);

const Services = () => {
    const { t } = useTranslation();

    const refs = useRef([]);

    const settings = {
        dots: true,
        infinite: true,
        arrows: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    const [matches, setMatches] = useState(
        window.matchMedia("(min-width: 768px)").matches
    )

    useEffect(() => {
        window
            .matchMedia("(min-width: 768px)")
            .addEventListener('change', e => setMatches(e.matches));
    }, []);

    useEffect(() => {
        // GSAP animasyonu: scroll ettikçe bileşen büyüsün
        refs.current.forEach((ref, index) => {
            gsap.fromTo(ref,
                { scale: 0.8, opacity: 0.5 },  // Başlangıç durumu
                {
                    opacity: 1,
                    scale: 1,  // Hedef durum
                    scrollTrigger: {
                        trigger: ref,
                        start: 'top bottom',
                        end: 'center center',
                        scrub: true,
                    }
                }
            );
        });

        gsap.fromTo(
            ".services_content_gsap",
            { y: 50, opacity: 0 },
            { duration: .7, y: 0, opacity: 1, ease: "power1.inOut", delay: .3 }
        );
    }, []);

    return (
        <div className='subpage_services_page'>
            <SubpageBanner title={t("services")} subtitle={t("ourservices_subtitle")} />
            <div className="subpage_services_text_content">
                <div className="container">
                    <div className="text-center services_content_gsap">
                        <h5 className='subpage_services_content_subtitle'>{t("ourservices_content_subtitle")}</h5>
                        <h3 className='subpage_services_content_title'>{t("ourservices_content_title")}</h3>
                    </div>
                    {
                        matches ?
                            <div className="row subpage_services_cards">
                                {
                                    ServicesApi.map((e, index) => (
                                        <div className={e.class} ref={el => refs.current[index] = el} key={e.id}>
                                            <Link to={`/service/${e.url}`} className="text-decoration-none">
                                                <div className={`service_card service_card${e.id}`} style={{ backgroundImage: `url(${e.image})` }}>
                                                    <h3 className='service_card_title'>
                                                        {t(e.title)}
                                                    </h3>
                                                </div>
                                            </Link>
                                        </div>
                                    ))
                                }
                            </div>
                            :
                            <div className='mobile_servicepage_content mt-5'>
                                <Slider {...settings}>
                                    {
                                        ServicesApi.map((e, index) => (
                                            <div ref={el => refs.current[index] = el} key={e.id}>
                                                <Link to={`/service/${e.url}`} className="text-decoration-none">
                                                    <div className={`service_card service_card${e.id}`} style={{ backgroundImage: `url(${e.imageSmall})` }}>
                                                        <h3 className='service_card_title'>
                                                            {t(e.title)}
                                                        </h3>
                                                    </div>
                                                </Link>
                                            </div>
                                        ))
                                    }
                                </Slider>
                            </div>
                    }
                </div>
            </div>
            <Cta />
        </div>
    )
}

export default Services