import React, { useEffect } from 'react'
import Logo from "../assets/images/logo/codiatech-svg.svg"
import { Link, useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion';
import LanguageSwitcher from './LanguageSwitcher/LanguageSwitcher';
import { useTranslation } from 'react-i18next';
import { Offcanvas } from 'bootstrap'; // Bootstrap Offcanvas API

const Header = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const clickHandler = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }

    const closeOffcanvas = () => {
        const offcanvasElement = document.getElementById('offcanvasExample');
        const bsOffcanvas = Offcanvas.getInstance(offcanvasElement);
        if (bsOffcanvas) {
            bsOffcanvas.hide(); // Menüyü kapat
        }

        const backdropElement = document.querySelector('.offcanvas-backdrop');
        if (backdropElement) {
            backdropElement.remove();
        }
    }

    const scrollToTechnologies = () => {
        if (window.location.pathname === "/") {
            // Eğer ana sayfadaysanız direkt scroll edin
            document.getElementById("homepage_technologies_content").scrollIntoView({
                behavior: "smooth"
            });
        } else {
            // Başka bir sayfadaysanız ana sayfaya yönlendirin ve sonra scroll edin
            navigate("/");
            setTimeout(() => {
                document.getElementById("homepage_technologies_content").scrollIntoView({
                    behavior: "smooth"
                });
            }, 500); // Ana sayfanın yüklenmesi için küçük bir gecikme
        }
    };

    useEffect(() => {
        const offcanvasElement = document.getElementById('offcanvasExample');
        const offcanvasInstance = new Offcanvas(offcanvasElement);

        const handleOffcanvasHide = () => {
            document.body.style.overflow = 'auto';
        };

        const handleOffcanvasShow = () => {
            document.body.style.overflow = 'hidden';
        };

        offcanvasElement.addEventListener('hidden.bs.offcanvas', handleOffcanvasHide);
        offcanvasElement.addEventListener('shown.bs.offcanvas', handleOffcanvasShow);

        return () => {
            offcanvasElement.removeEventListener('hidden.bs.offcanvas', handleOffcanvasHide);
            offcanvasElement.removeEventListener('shown.bs.offcanvas', handleOffcanvasShow);
        };
    }, []);

    const techButton = () => {
        scrollToTechnologies()
        closeOffcanvas()
    }

    return (
        <>
            <div className="header_content">
                <div className='container'>
                    <div className='row align-items-center'>
                        <div className='col-xl-2 col-lg-2'>
                            <Link to="/" onClick={clickHandler}>
                                <img src={Logo} alt="codiatech-logo" width="140px" />
                            </Link>
                        </div>
                        <div className='col-xl-8 col-lg-8'>
                            <ul className='navigation-list'>
                                <li>
                                    <Link to="/" onClick={clickHandler}>
                                        {t("homepage")}
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/about">
                                        {t("about")}
                                    </Link>
                                </li>
                                <li>
                                    <button onClick={scrollToTechnologies} style={{ backgroundColor: "transparent", border: "none", color: "#1F1F1F" }}>
                                        {t("technologiesweuse")}
                                    </button>
                                </li>
                                <li>
                                    <Link to="/services">
                                        {t("services")}
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/contact">
                                        {t("contact")}
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-xl-2 col-lg-2 header_lg_buttons">
                            <div className="d-flex align-items-center justify-content-end">
                                <Link to="/get-offer" className='text-white text-decoration-none me-2'>
                                    <motion.button
                                        className="small_proposal_btn"
                                        initial={{ backgroundPosition: 'right' }}
                                        whileHover={{ backgroundPosition: 'left', transition: { duration: 0.5 } }}
                                    >
                                        {t("getaquote")}
                                    </motion.button>
                                </Link>
                                <LanguageSwitcher />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mobile_header_content">
                <div className="container">
                    <div className="d-flex align-items-center justify-content-between">
                        <div>
                            <Link to="/">
                                <img src={Logo} alt="codiatech-logo" width="150px" />
                            </Link>
                        </div>
                        <div>
                            <button className="hamburger_icon" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
                                <img width="24" height="24" src="https://img.icons8.com/material-rounded/24/ffffff/menu--v1.png" alt="menu--v1" />
                            </button>
                            <div className="offcanvas offcanvas-start" tabIndex={-1} id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
                                <div className="offcanvas-header justify-content-between border-bottom">
                                    <div>
                                        <img src={Logo} alt="codiatech-logo" width="150px" />
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <LanguageSwitcher />
                                        <button type="button" className="btn-close ms-3" data-bs-dismiss="offcanvas" aria-label="Close" />
                                    </div>
                                </div>
                                <div className="offcanvas-body">
                                    <ul>
                                        <li>
                                            <Link to="/" onClick={closeOffcanvas}>{t("homepage")}</Link>
                                        </li>
                                        <li>
                                            <Link to="/about" onClick={closeOffcanvas}>{t("about")}</Link>
                                        </li>
                                        <li>
                                            <button onClick={techButton}>{t("technologiesweuse")}</button>
                                        </li>
                                        <li>
                                            <Link to="/services" onClick={closeOffcanvas}>{t("services")}</Link>
                                        </li>
                                        <li>
                                            <Link to="/contact" onClick={closeOffcanvas}>{t("contact")}</Link>
                                        </li>
                                        <li>
                                            <Link to="/get-offer" onClick={closeOffcanvas} className='text-white text-decoration-none me-2'>
                                                <motion.button
                                                    className="small_proposal_btn text-white w-100 py-2 rounded"
                                                    initial={{ backgroundPosition: 'right' }}
                                                    whileHover={{ backgroundPosition: 'left', transition: { duration: 0.5 } }}
                                                >
                                                    {t("getaquote")}
                                                </motion.button>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Header