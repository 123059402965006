import React, { useEffect, useState } from 'react';
import Logo from "../assets/images/logo/codiatech-svg.svg";
import { gsap } from 'gsap';
import BannerCarousel from './BannerCarousel';
import Overlay from "../assets/videos/banner-overlay-2.mp4"
import OverlayMobile from "../assets/images/video-to-jpg/banner.jpg"
import { ReactTyped } from "react-typed";
import { useTranslation } from 'react-i18next';

const Banner = () => {
    const { t } = useTranslation()
    useEffect(() => {
        // GSAP animasyonları
        gsap.fromTo(
            ".banner_content h1",
            { y: 100, opacity: 0 },
            { duration: .7, y: 0, opacity: 1, ease: "power1.inOut" }
        );

        gsap.fromTo(
            ".banner_content p.mb-4",
            { y: 100, opacity: 0 },
            { duration: .7, y: 0, opacity: 1, ease: "power1.inOut", delay: .2 }
        );

        gsap.fromTo(
            ".banner_content p.mb-0",
            { x: -100, opacity: 0 },
            { duration: .7, x: 0, opacity: 1, ease: "power1.inOut", delay: .5 }
        );
    }, []);

    const [matches, setMatches] = useState(
        window.matchMedia("(min-width: 768px)").matches
    )

    useEffect(() => {
        window
            .matchMedia("(min-width: 768px)")
            .addEventListener('change', e => setMatches(e.matches));
    }, []);

    return (
        <div className='banner_content'>
            <div className="container">
                <h1>
                    {t("banner_title")}
                </h1>
                <ReactTyped
                    className="h1 banner_sliding_text"
                    strings={[
                        t("sliding_text1"),
                        t("sliding_text2"),
                        t("sliding_text3")
                    ]}
                    typeSpeed={80}
                    backSpeed={40}
                    loop
                />
                <p className='mb-0 mt-4 w-75 banner_text'>
                    {t("banner_text")}
                </p>
                {/* <BannerCarousel /> */}
            </div>
            {
                matches ?
                    <video className="banner_video" autoPlay loop muted playsInline preload="auto" >
                        <source src={Overlay} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                    :
                    <img src={OverlayMobile} alt="banner" style={{ position: "absolute", width: "100%", top: "0", zIndex: "-1" }} />
            }
            <div className="banner_overlay_logo">
                <img src={Logo} alt="codiatech-logo" width="100%" />
            </div>
        </div>
    );
}

export default Banner;
