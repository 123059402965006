import React, { useEffect, useRef } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useTranslation } from 'react-i18next';

// PHOTOS
import Csharp from "../assets/images/icons/c-sharp.png"
import NetCore from "../assets/images/icons/net-core.png"
import NetFrmw from "../assets/images/icons/net-framework.png"
import Node from "../assets/images/icons/nodejs.png"
import Angular from "../assets/images/icons/angular.png"
import Vue from "../assets/images/icons/vuejs.png"
import Sql from "../assets/images/icons/sql-server.png"
import Mongo from "../assets/images/icons/mongo.png"
import Redis from "../assets/images/icons/redis.png"
import Elastic from "../assets/images/icons/elastic.png"
import Influx from "../assets/images/icons/influx.png"
import Postgre from "../assets/images/icons/postgre.png"
import Azure from "../assets/images/icons/azure.png"
import Aws from "../assets/images/icons/aws.png"
import Cloud from "../assets/images/icons/google-cloud.png"
import Docker from "../assets/images/icons/docker.png"
import Kubernetes from "../assets/images/icons/kubernetes.png"
import Nginx from "../assets/images/icons/nginx.png"
import Prometheus from "../assets/images/icons/prometheus.png"
import Zabbix from "../assets/images/icons/zabbix.png"
import Iis from "../assets/images/icons/iis.jpg"
import Flutter from "../assets/images/icons/flutter.png"
import Maui from "../assets/images/icons/maui.svg"
import Swift from "../assets/images/icons/swift.png"
import Kotlin from "../assets/images/icons/kotlin.png"
import Hangfire from "../assets/images/icons/hangfire.svg"
import Serilog from "../assets/images/icons/serilog.png"
import AutoMapper from "../assets/images/icons/automapper.png"
import Swagger from "../assets/images/icons/swagger.png"
import Blazor from "../assets/images/icons/blazor.png"
import Signal from "../assets/images/icons/signalr.svg"
import Rabbit from "../assets/images/icons/rabbitmq.png"
import Kafka from "../assets/images/icons/kafka.png"
import EntityFrmw from "../assets/images/icons/entity-framework-core.png"
import Mediatr from "../assets/images/icons/mediatr.png"

gsap.registerPlugin(ScrollTrigger);

const Technologies = () => {
    const { t } = useTranslation()
    const technologiesRef = useRef(null);

    useEffect(() => {
        // GSAP animasyonu: scroll ettikçe bileşen büyüsün
        gsap.fromTo(technologiesRef.current,
            { scale: 0.6 }, // başlangıç boyutu (küçük)
            {
                scale: 1,    // hedef boyut (tam)
                scrollTrigger: {
                    trigger: technologiesRef.current,
                    start: 'top bottom', // component ekrana girmeye başladığında
                    end: 'center center',   // component tam ekrana geldiğinde
                    scrub: true,         // yavaş yavaş büyüme efektini sağlayacak
                }
            }
        );
    }, []);

    return (
        <div className='technologies_content' id="homepage_technologies_content">
            <div className="container" ref={technologiesRef}>
                <h3 className='homepage_section_title text-center'>
                    {t("homepage_technologies_title")}
                </h3>
                <div className='technologies_tab_content'>
                    <Tabs>
                        <TabList>
                            <Tab>Web Technologies</Tab>
                            <Tab>Mobile Apps</Tab>
                            <Tab>Cloud & DevOps</Tab>
                            <Tab>Databases</Tab>
                            <Tab>Other Frameworks</Tab>
                        </TabList>

                        <TabPanel>
                            <div className='row justify-content-center'>
                                <div className="col-lg-10">
                                    <div className="row justify-content-center">
                                        <div className="col-lg-2 col-6">
                                            <img src="https://techco-react.xpressbuddy.com/static/media/icon_javascript.1973b23c88adaaddffc6f9be14d7d9e1.svg" alt="" width="60px" />
                                            <p className="mb-0">JavaScript</p>
                                        </div>
                                        <div className="col-lg-2 col-6">
                                            <img src={Csharp} alt="" width="60px" />
                                            <p className="mb-0">C#</p>
                                        </div>
                                        <div className="col-lg-2 col-6 mt-lg-0 mt-md-0 mt-5">
                                            <img src={NetCore} alt="" width="60px" />
                                            <p className="mb-0">.Net Core</p>
                                        </div>
                                        <div className="col-lg-2 col-6 mt-lg-0 mt-md-0 mt-5">
                                            <img src={NetFrmw} alt="" width="105px" />
                                            <p className="mb-0">.Net Framework</p>
                                        </div>
                                        <div className="col-lg-2 col-6 mt-lg-0 mt-md-0 mt-5">
                                            <img src="https://techco-react.xpressbuddy.com/static/media/icon_react_js.68995fd07e143d7cf1537c7e9428982f.svg" alt="" width="60px" />
                                            <p className="mb-0">React Js</p>
                                        </div>
                                        <div className="col-lg-2 col-6 mt-lg-0 mt-md-0 mt-5">
                                            <div className='tech-icon'>
                                                <img src={Node} alt="" width="150px" />
                                            </div>
                                            <p className="mb-0">Node Js</p>
                                        </div>
                                        <div className="col-lg-2 col-6 mt-5">
                                            <img src={Angular} alt="" width="60px" />
                                            <p className="mb-0">Angular</p>
                                        </div>
                                        <div className="col-lg-2 col-6 mt-5">
                                            <img src="https://techco-react.xpressbuddy.com/static/media/icon_python.7d02a92bdd3f798e02b71b0dbbff219d.svg" alt="" width="60px" />
                                            <p className="mb-0">Phyton</p>
                                        </div>
                                        <div className="col-lg-2 col-6 mt-5">
                                            <img src={Vue} alt="" width="60px" />
                                            <p className="mb-0">Vue Js</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className='row justify-content-center'>
                                <div className="col-lg-10">
                                    <div className="row justify-content-center">
                                        <div className="col-lg-2 col-6">
                                            <div className="tech-icon">
                                                <img src="https://techco-react.xpressbuddy.com/static/media/icon_react_js.68995fd07e143d7cf1537c7e9428982f.svg" alt="" width="60px" />
                                            </div>
                                            <p className="mb-0">React Native</p>
                                        </div>
                                        <div className="col-lg-2 col-6">
                                            <div className="tech-icon">
                                                <img src={Flutter} alt="" width="120px" />
                                            </div>
                                            <p className="mb-0">Flutter</p>
                                        </div>
                                        <div className="col-lg-2 col-6 mt-lg-0 mt-md-0 mt-5">
                                            <div className="tech-icon">
                                                <img src={Maui} alt="" width="70px" />
                                            </div>
                                            <p className="mb-0">MAUI</p>
                                        </div>
                                        <div className="col-lg-2 col-6 mt-lg-0 mt-md-0 mt-5">
                                            <div className="tech-icon">
                                                <img src={Swift} alt="" width="100px" />
                                            </div>
                                            <p className="mb-0">Swift</p>
                                        </div>
                                        <div className="col-lg-2 col-6 mt-lg-0 mt-md-0 mt-5">
                                            <div className="tech-icon">
                                                <img src={Kotlin} alt="" width="60px" />
                                            </div>
                                            <p className="mb-0">Kotlin</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className='row justify-content-center'>
                                <div className="col-lg-10">
                                    <div className="row justify-content-center">
                                        <div className="col-lg-2 col-6">
                                            <div className='tech-icon'>
                                                <img src={Azure} alt="" width="60px" />
                                            </div>
                                            <p className="mb-0">Microsoft Azure</p>
                                        </div>
                                        <div className="col-lg-2 col-6">
                                            <div className='tech-icon'>
                                                <img src={Aws} alt="" width="75px" />
                                            </div>
                                            <p className="mb-0">Amazon Web Services</p>
                                        </div>
                                        <div className="col-lg-2 col-6 mt-lg-0 mt-md-0 mt-5">
                                            <div className='tech-icon'>
                                                <img src={Cloud} alt="" width="70px" />
                                            </div>
                                            <p className="mb-0">Google Cloud Platform</p>
                                        </div>
                                        <div className="col-lg-2 col-6 mt-lg-0 mt-md-0 mt-5">
                                            <div className='tech-icon'>
                                                <img src={Docker} alt="" width="130px" />
                                            </div>
                                            <p className="mb-0">Docker</p>
                                        </div>
                                        <div className="col-lg-2 col-6 mt-lg-0 mt-md-0 mt-5">
                                            <div className='tech-icon'>
                                                <img src={Kubernetes} alt="" width="130px" />
                                            </div>
                                            <p className="mb-0">Kubernetes</p>
                                        </div>
                                        <div className="col-lg-2 col-6 mt-lg-0 mt-md-0 mt-5">
                                            <div className='tech-icon'>
                                                <img src={Nginx} alt="" width="100px" />
                                            </div>
                                            <p className="mb-0">NGINX</p>
                                        </div>
                                        <div className="col-lg-2 col-6 mt-5">
                                            <div className='tech-icon'>
                                                <img src={Prometheus} alt="" width="130px" />
                                            </div>
                                            <p className="mb-0">Prometheus & Grafana</p>
                                        </div>
                                        <div className="col-lg-2 col-6 mt-5">
                                            <div className='tech-icon'>
                                                <img src={Zabbix} alt="" width="75px" />
                                            </div>
                                            <p className="mb-0">Zabbix</p>
                                        </div>
                                        <div className="col-lg-2 col-6 mt-5">
                                            <div className='tech-icon'>
                                                <img src={Iis} alt="" width="100px" />
                                            </div>
                                            <p className="mb-0">IIS</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className='row justify-content-center'>
                                <div className="col-lg-10">
                                    <div className="row justify-content-center">
                                        <div className="col-lg-2 col-6">
                                            <div className='tech-icon'>
                                                <img src={Sql} alt="" width="80px" />
                                            </div>
                                            <p className="mb-0">Microsoft Sql Server</p>
                                        </div>
                                        <div className="col-lg-2 col-6">
                                            <div className="tech-icon">
                                                <img src={Mongo} alt="" width="130px" />
                                            </div>
                                            <p className="mb-0">Mongo DB</p>
                                        </div>
                                        <div className="col-lg-2 col-6 mt-lg-0 mt-md-0 mt-5">
                                            <div className="tech-icon">
                                                <img src={Redis} alt="" width="110px" />
                                            </div>
                                            <p className="mb-0">Redis</p>
                                        </div>
                                        <div className="col-lg-2 col-6 mt-lg-0 mt-md-0 mt-5">
                                            <div className="tech-icon">
                                                <img src={Elastic} alt="" width="140px" />
                                            </div>
                                            <p className="mb-0">Elastic Search</p>
                                        </div>
                                        <div className="col-lg-2 col-6 mt-lg-0 mt-md-0 mt-5">
                                            <div className="tech-icon">
                                                <img src={Influx} alt="" width="120px" />
                                            </div>
                                            <p className="mb-0">Influx Db</p>
                                        </div>
                                        <div className="col-lg-2 col-6 mt-lg-0 mt-md-0 mt-5">
                                            <img src={Postgre} alt="" width="60px" />
                                            <p className="mb-0">PostgreSQL</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className='row justify-content-center'>
                                <div className="col-lg-10">
                                    <div className="row justify-content-center">
                                        <div className="col-lg-2 col-6">
                                            <div className="tech-icon">
                                                <img src={Hangfire} alt="" width="130px" />
                                            </div>
                                            <p className="mb-0">Hangfire</p>
                                        </div>
                                        <div className="col-lg-2 col-6">
                                            <div className="tech-icon">
                                                <img src={Serilog} alt="" width="60px" />
                                            </div>
                                            <p className="mb-0">Serilog</p>
                                        </div>
                                        <div className="col-lg-2 col-6 mt-lg-0 mt-md-0 mt-5">
                                            <div className="tech-icon">
                                                <img src={AutoMapper} alt="" width="60px" />
                                            </div>
                                            <p className="mb-0">AutoMapper</p>
                                        </div>
                                        <div className="col-lg-2 col-6 mt-lg-0 mt-md-0 mt-5">
                                            <div className="tech-icon">
                                                <img src={Swagger} alt="" width="60px" />
                                            </div>
                                            <p className="mb-0">Swagger</p>
                                        </div>
                                        <div className="col-lg-2 col-6 mt-lg-0 mt-md-0 mt-5">
                                            <div className="tech-icon">
                                                <img src={Blazor} alt="" width="60px" />
                                            </div>
                                            <p className="mb-0">Blazor</p>
                                        </div>
                                        <div className="col-lg-2 col-6 mt-lg-0 mt-md-0 mt-5">
                                            <div className="tech-icon">
                                                <img src={Signal} alt="" width="125px" />
                                            </div>
                                            <p className="mb-0">SignalR</p>
                                        </div>
                                        <div className="col-lg-2 col-6 mt-5">
                                            <div className="tech-icon">
                                                <img src={Rabbit} alt="" width="60px" />
                                            </div>
                                            <p className="mb-0">Rabbit Mq</p>
                                        </div>
                                        <div className="col-lg-2 col-6 mt-5">
                                            <div className="tech-icon">
                                                <img src={Kafka} alt="" width="110px" />
                                            </div>
                                            <p className="mb-0">Kafka</p>
                                        </div>
                                        <div className="col-lg-2 col-6 mt-5">
                                            <div className="tech-icon">
                                                <img src={EntityFrmw} alt="" width="60px" />
                                            </div>
                                            <p className="mb-0">Entity Framework Core</p>
                                        </div>
                                        <div className="col-lg-2 col-6 mt-5">
                                            <div className="tech-icon">
                                                <img src={Mediatr} alt="" width="120px" />
                                            </div>
                                            <p className="mb-0">MediatR</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>
                    </Tabs>
                </div>
            </div>
        </div>
    )
}

export default Technologies