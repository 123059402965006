import React, { useRef, useEffect, useState } from 'react';
import { gsap } from 'gsap';
import { motion } from 'framer-motion';
import MaskedInput from 'react-text-mask';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2'

// Yup validation schema
const validationSchema = Yup.object({
    name: Yup.string().required('İsim Soyisim gerekli'),
    email: Yup.string().email('Geçersiz e-posta').required('E-posta Adresi gerekli'),
    phone: Yup.string().required('Telefon Numarası gerekli'),
    message: Yup.string().required('Mesaj gerekli'),
});

// ContactForm component
const ContactForm = () => {
    const { t } = useTranslation()
    const labelRefs = useRef([]);
    const [inputValues, setInputValues] = useState({
        name: '',
        email: '',
        phone: '',
        message: '',
    });

    const handleFocus = (index) => {
        gsap.to(labelRefs.current[index], {
            y: -25,
            x: -15,
            scale: 0.8,
            color: "#ED6F1C",
            duration: 0.1,
            ease: 'power2.out',
        });
    };

    const handleBlur = (index, e) => {
        const { value } = e.target;
        if (value === '') {
            gsap.to(labelRefs.current[index], {
                y: 0,
                x: 0,
                scale: 1,
                color: "rgb(136, 136, 136)",
                duration: 0.3,
                ease: 'power2.out',
            });
        }
        setInputValues({ ...inputValues, [e.target.name]: value });
    };

    useEffect(() => {
        Object.keys(inputValues).forEach((key, index) => {
            if (inputValues[key]) {
                gsap.to(labelRefs.current[index], {
                    y: -25,
                    x: -10,
                    scale: 0.8,
                    color: "rgb(136, 136, 136)",
                    duration: 0.1,
                    ease: 'power2.out'
                });
            }
        });
    }, [inputValues]);

    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        // console.log(values)
        // try {
        //     // API isteği yapılıyor
        //     await axios.post('/api/submit', values);
        //     // Başarı mesajı
        //     alert('Form başarıyla gönderildi!');
        // } catch (error) {
        //     // Hata mesajı
        //     alert('Form gönderilirken bir hata oluştu.');
        // }
        // setSubmitting(false);
        Swal.fire({
            title: t("success"),
            text: t("successmessage"),
            icon: "success"
        });
        resetForm();
    };

    return (
        <Formik
            initialValues={inputValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {({ errors, touched }) => (
                <Form className='contactform_content'>
                    <div className="row justify-content-center">
                        <div className="col-lg-6 contactform_rightside">
                            {/* İsim Soyisim */}
                            <div className="form-group" style={{ position: 'relative', marginBottom: '35px' }}>
                                <label
                                    ref={(el) => (labelRefs.current[0] = el)}
                                    htmlFor="name"
                                    className="contact_label"
                                >
                                    {t("fullname")}
                                </label>
                                <Field
                                    type="text"
                                    id="name"
                                    name="name"
                                    onFocus={() => handleFocus(0)}
                                    onBlur={(e) => handleBlur(0, e)}
                                    className="contact_input"
                                />
                                <ErrorMessage name="name" component="div" className="error-message" />
                            </div>

                            {/* E-posta Adresi */}
                            <div className="form-group" style={{ position: 'relative', marginBottom: '35px' }}>
                                <label
                                    ref={(el) => (labelRefs.current[1] = el)}
                                    htmlFor="email"
                                    className="contact_label"
                                >
                                    {t("email")}
                                </label>
                                <Field
                                    type="email"
                                    id="email"
                                    name="email"
                                    onFocus={() => handleFocus(1)}
                                    onBlur={(e) => handleBlur(1, e)}
                                    className="contact_input"
                                />
                                <ErrorMessage name="email" component="div" className="error-message" />
                            </div>

                            {/* Telefon Numarası */}
                            <div className="form-group" style={{ position: 'relative', marginBottom: '35px' }}>
                                <label
                                    ref={(el) => (labelRefs.current[2] = el)}
                                    htmlFor="phone"
                                    className="contact_label"
                                >
                                    {t("phone")}
                                </label>
                                <Field
                                    as={MaskedInput}
                                    mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/]}
                                    guide={false}
                                    type="tel"
                                    id="phone"
                                    name="phone"
                                    onFocus={() => handleFocus(2)}
                                    onBlur={(e) => handleBlur(2, e)}
                                    className="contact_input"
                                />
                                <ErrorMessage name="phone" component="div" className="error-message" />
                            </div>

                            {/* İletmek İstediğiniz Mesaj */}
                            <div className="form-group" style={{ position: 'relative', marginBottom: '35px' }}>
                                <label
                                    ref={(el) => (labelRefs.current[3] = el)}
                                    htmlFor="message"
                                    className="contact_label"
                                >
                                    {t("yourmessage")}
                                </label>
                                <Field
                                    as="textarea"
                                    id="message"
                                    name="message"
                                    onFocus={() => handleFocus(3)}
                                    onBlur={(e) => handleBlur(3, e)}
                                    className="contact_input contact_text_area"
                                />
                                <ErrorMessage name="message" component="div" className="error-message" />
                            </div>

                            <div>
                                <motion.button
                                    type="submit"
                                    className="proposal_btn w-100"
                                    style={{ fontSize: "18px" }}
                                    initial={{ backgroundPosition: 'left' }}
                                    whileHover={{ backgroundPosition: 'right', transition: { duration: 0.5 } }}
                                >
                                    {t("sendmessage")}
                                </motion.button>
                            </div>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    );
}

export default ContactForm;
