import Homepage from './pages/Homepage';
import './scss/index.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'react-tabs/style/react-tabs.css';
import Header from './components/Header';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import About from './pages/About';
import Services from './pages/Services';
import ServiceDetail from './pages/ServiceDetail';
import Contact from './pages/Contact';
import GetOffer from './pages/GetOffer';
import "./i18n/i18n";

function App() {
    return (
        <>
            <ScrollToTop />
            <Header />
            <Routes>
                <Route path='/' exact element={<Homepage />} />
                <Route path='/about' exact element={<About />} />
                <Route path='/services' exact element={<Services />} />
                <Route path='/service/:title' exact element={<ServiceDetail />} />
                <Route path='/contact' exact element={<Contact />} />
                <Route path='/get-offer' exact element={<GetOffer />} />
            </Routes>
            <Footer />
        </>
    );
}

export default App;
