import React, { useState, useEffect } from 'react'
import Header from '../components/Header'
import Banner from '../components/Banner'
import Services from '../components/Services'
import ServicesMobile from '../components/ServicesMobile'
import Technologies from '../components/Technologies'
import WhyUs from '../components/WhyUs'
import Cta from '../components/Cta'
import ContactForm from '../components/ContactForm'

const Homepage = () => {
    const [matches, setMatches] = useState(
        window.matchMedia("(min-width: 768px)").matches
    )

    useEffect(() => {
        window
            .matchMedia("(min-width: 768px)")
            .addEventListener('change', e => setMatches(e.matches));
    }, []);
    return (
        <>
            <Banner />
            {
                matches ?
                    <Services />
                    :
                    <ServicesMobile />
            }
            <Technologies />
            <WhyUs />
            <Cta />
        </>
    )
}

export default Homepage