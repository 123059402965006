import React, { useRef, useEffect } from 'react';
import SubpageBanner from '../components/SubpageBanner';
import Cta from '../components/Cta';
import Peoples from "../assets/images/about/peoples-mask.svg";
import Banner from "../assets/images/about/banner.png";
import Peoples2 from "../assets/images/about/with-copy-space-friends-join-hands-as-teamwork-concept.jpg";

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useTranslation } from 'react-i18next';

gsap.registerPlugin(ScrollTrigger);

const About = () => {
    const { t } = useTranslation()
    const aboutusimage = useRef(null);
    const content2Ref = useRef(null);

    useEffect(() => {
        // GSAP animasyonu: scroll ettikçe bileşen büyüsün
        gsap.fromTo(aboutusimage.current,
            { opacity: 0 }, // başlangıç boyutu (küçük)
            {
                opacity: 1,   // hedef boyut (tam)
                scrollTrigger: {
                    trigger: aboutusimage.current,
                    start: 'top bottom', // component ekrana girmeye başladığında
                    end: 'center center',   // component tam ekrana geldiğinde
                    scrub: true,         // yavaş yavaş büyüme efektini sağlayacak
                }
            }
        );

        // subpage_aboutus_content2 sağdan ve soldan gelen animasyonlar
        gsap.fromTo(content2Ref.current.querySelector('.col-lg-6'),
            { x: -50, opacity: 0 },  // soldan gelen animasyon
            {
                x: 0, opacity: 1,
                scrollTrigger: {
                    trigger: content2Ref.current,
                    start: 'top 80%', // scroll hizaya geldiğinde başlasın
                    end: 'top 60%',
                    scrub: true,
                }
            }
        );

        gsap.fromTo(content2Ref.current.querySelector('.subpage_about_rightimage'),
            { x: 50, opacity: 0 },  // sağdan gelen animasyon
            {
                x: 0, opacity: 1,
                scrollTrigger: {
                    trigger: content2Ref.current,
                    start: 'top 80%', // scroll hizaya geldiğinde başlasın
                    end: 'top 60%',
                    scrub: true,
                }
            }
        );

        // GSAP animasyonları
        gsap.fromTo(
            ".subpage_about_leftimage",
            { y: -100, opacity: 0 },
            { duration: .7, y: 0, opacity: 1, ease: "power1.inOut", delay: .3 }
        );
        gsap.fromTo(
            ".subpage_about_firsttext",
            { y: 100, opacity: 0 },
            { duration: .7, y: 0, opacity: 1, ease: "power1.inOut", delay: .3 }
        );
    }, []);

    return (
        <>
            <SubpageBanner title={t("about")} subtitle={t("about_subtitle")} />
            <div className="subpage_about_content">
                <div className="container">
                    <div className="row justify-content-end position-relative">
                        <div className="subpage_about_leftimage">
                            <img src={Peoples} alt="" />
                        </div>
                        <div className="col-lg-6 subpage_about_firsttext">
                            <h3 className='mb-4 subpage_about_title'>
                                {t("about_valuesfuture_title")}
                            </h3>
                            <p className='mb-4 subpage_about_text'>
                                {t("about_valuesfuture_text")}
                            </p>
                            <h5>
                                {t("about_valuesfuture_subtext")}
                            </h5>
                        </div>
                    </div>
                    <div className="subpage_aboutus_image" ref={aboutusimage}>
                        <img src={Peoples2} alt="" width="100%" className='shadow-sm' />
                    </div>
                    <div className="row align-items-center position-relative subpage_aboutus_content2" ref={content2Ref}>
                        <div className="col-lg-6">
                            <h3 className='mb-4 subpage_about_title'>
                                {t("about_ourstory")}
                            </h3>
                            <p className='mb-3 subpage_about_text'>
                                {t("about_ourstory_text1")}
                            </p>
                            <p className='mb-3 subpage_about_text'>
                                {t("about_ourstory_text2")}
                            </p>
                            <p className='mb-3 subpage_about_text'>
                                {t("about_ourstory_text3")}
                            </p>
                            <p className='subpage_about_text'>
                                {t("about_ourstory_text4")}
                            </p>
                        </div>
                        <div className="subpage_about_rightimage">
                            <img src={Banner} alt="" width="50%" />
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="subpage_aboutus_cta">
                <Cta />
            </div> */}
        </>
    )
}

export default About;
