import React, { useEffect } from 'react'
import { gsap } from 'gsap';
import SubpageBanner from '../components/SubpageBanner'
import ContactForm from '../components/ContactForm'
import Logo from "../assets/images/logo/codiatech-svg.svg"
import OfferForm from '../components/OfferForm';
import { useTranslation } from 'react-i18next';

const Contact = () => {
    const { t } = useTranslation()
    useEffect(() => {
        // GSAP animasyonları
        gsap.fromTo(
            ".subpage_contact_text_area",
            { y: 100, opacity: 0 },
            { duration: .7, y: 0, opacity: 1, ease: "power1.inOut", delay: .3 }
        );
    }, []);

    return (
        <>
            <SubpageBanner title={t("getaquote")} subtitle={t("offer_subtitle")} />
            <div className="subpage_contact_content position-relative">
                <div className="container">
                    <div className="text-center subpage_contact_text_area">
                        <h5 className='subpage_contact_content_subtitle'>{t("offer_content_subtitle")}</h5>
                        <h3 className='subpage_contact_content_title w-50 mx-auto'>{t("offer_content_title")}</h3>
                    </div>
                    <OfferForm />
                </div>
                {/* <div className='subpage_contact_logo'>
                    <img src={Logo} alt="" />
                </div> */}
            </div>
        </>
    )
}

export default Contact