import React from 'react'
import Logo from "../assets/images/logo/codiatech-svg.svg"
import { Link, useNavigate } from 'react-router-dom'
import Instagram from "../assets/images/footer/instagram.svg"
import Linkedin from "../assets/images/footer/linkedin.png"
import { useTranslation } from 'react-i18next'

const Footer = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const techButton = () => {
        if (window.location.pathname === "/") {
            // Eğer ana sayfadaysanız direkt scroll edin
            document.getElementById("homepage_technologies_content").scrollIntoView({
                behavior: "smooth"
            });
        } else {
            // Başka bir sayfadaysanız ana sayfaya yönlendirin ve sonra scroll edin
            navigate("/");
            setTimeout(() => {
                document.getElementById("homepage_technologies_content").scrollIntoView({
                    behavior: "smooth"
                });
            }, 500); // Ana sayfanın yüklenmesi için küçük bir gecikme
        }
    }
    return (
        <div className='footer_content'>
            <div className="container">
                <div className="row border-bottom pb-5 footer_mobile_content">
                    <div className="col-lg-3 mb-lg-0 mb-md-0 mb-5">
                        <img src={Logo} alt="codiatech-logo" width="250px" />
                    </div>
                    <div className="col-lg-3 mb-lg-0 mb-md-0 mb-5">
                        <h5>{t("quickaccess")}</h5>
                        <ul>
                            <li>
                                <Link to="/">{t("homepage")}</Link>
                            </li>
                            <li>
                                <Link to="/about">{t("about")}</Link>
                            </li>
                            <li>
                                <button onClick={techButton} style={{ backgroundColor: "transparent", border: "none", color: "#1F1F1F" }}>{t("technologiesweuse")}</button>
                            </li>
                            <li>
                                <Link to="/services">{t("services")}</Link>
                            </li>
                            <li>
                                <Link to="/get-offer">{t("getaquote")}</Link>
                            </li>
                            <li>
                                <Link to="/contact">{t("contact")}</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="col-lg-3 mb-lg-0 mb-md-0 mb-5">
                        <h5>{t("contact")}</h5>
                        <div className='footer_contact'>
                            {/* <div>
                                <p className="mb-0">{t("phone")}: 0555 555 55 55</p>
                            </div> */}
                            <div>
                                <a href="mailto:info@codiatech.com" className="mb-0"><span style={{ fontWeight: 600 }}>{t("email")}</span>: info@codiatech.com</a>
                            </div>
                            <div>
                                <a href='https://maps.app.goo.gl/BiR47CdURSLqJDB77' target='_blank' className="mb-0"><span style={{ fontWeight: 600 }}>{t("location")}</span>: Esentepe Mahallesi, Talatpaşa Caddesi, No: 5/1, Kolektif House Levent, İstanbul</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <h5>{t("followus")}</h5>
                        <div className='contact_socialmedia'>
                            <a href="https://www.instagram.com/codiatechofficial/" target='_blank'>
                                <img src={Instagram} alt="codiatech-Instagram" width="24px" />
                            </a>
                            <a href="https://www.linkedin.com/company/codiatechofficial/" target='_blank'>
                                <img src={Linkedin} alt="codiatech-linkedin" width="24px" />
                            </a>
                        </div>
                    </div>
                </div>
                <div className='pt-4 copyright_mobile'>
                    ©2024 codiatech. {t("copyright")}
                </div>
            </div>
        </div>
    )
}

export default Footer