import React, { useEffect, useRef } from 'react'
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import CtaImg from "../assets/images/cta/icon.svg"
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

gsap.registerPlugin(ScrollTrigger);

const Cta = () => {
    const { t } = useTranslation()
    const ref = useRef(null);

    useEffect(() => {
        // GSAP animasyonu: scroll ettikçe bileşen büyüsün
        gsap.fromTo(ref.current,
            { scale: 0.6 }, // başlangıç boyutu (küçük)
            {
                scale: 1,    // hedef boyut (tam)
                scrollTrigger: {
                    trigger: ref.current,
                    start: 'top bottom', // component ekrana girmeye başladığında
                    end: 'center center',   // component tam ekrana geldiğinde
                    scrub: true,         // yavaş yavaş büyüme efektini sağlayacak
                }
            }
        );
    }, []);

    return (
        <div className='cta_content position-relative' ref={ref}>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-7 col-lg-10">
                        <div className='row align-items-center'>
                            <div className="col-lg-8">
                                <h5 className='cta_title mb-0'>{t("homepage_cta_text1")}</h5>
                            </div>
                            <div className="col-lg-4 cta_image">
                                <img src={CtaImg} alt="" width="100%" />
                            </div>
                        </div>
                        <div className='row align-items-center mt-3'>
                            <div className="col-lg-5">
                                {/* <button className='proposal_btn w-100 py-4'><span>TEKLİF ALIN</span></button> */}
                                <Link to="/get-offer">
                                    <motion.button
                                        className="proposal_btn w-100 py-4"
                                        style={{ textTransform: "uppercase" }}
                                        initial={{ backgroundPosition: 'right' }}
                                        whileHover={{ backgroundPosition: 'left', transition: { duration: 0.5 } }}
                                    >
                                        {t("getaquote")}
                                    </motion.button>
                                </Link>
                            </div>
                            <div className="col-lg-7 text-end">
                                <h5 className='cta_title mb-0 cta_subtitle'>{t("homepage_cta_text2")}</h5>
                            </div>
                        </div>
                        <div className='text-center'>
                            <p className="mb-0 cta_text">
                                {t("homepage_cta_text3")}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Cta