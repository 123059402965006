import React, { useRef, useLayoutEffect, useEffect, useState } from 'react'
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import ServicesApi from "../api/Services"
import { useTranslation } from 'react-i18next';

gsap.registerPlugin(ScrollTrigger);

const Services = () => {
    const { t } = useTranslation()
    const component = useRef();
    const slider = useRef();

    useLayoutEffect(() => {
        let ctx = gsap.context(() => {
            let panels = gsap.utils.toArray(".panel");
            gsap.to(panels, {
                xPercent: -80 * (panels.length - 1),
                ease: "none",
                scrollTrigger: {
                    trigger: component.current,
                    pin: true,
                    scrub: 1,
                    snap: 1 / (panels.length - 1),
                    end: () => "+=" + slider.current.offsetWidth,
                    markers: false
                }
            });
        }, component);
        return () => ctx.revert();
    });



    return (
        <div className='services_content' ref={component}>
            <div className="container">
                <h3 className='homepage_section_title'>
                    {t("homepage_services_title")}
                </h3>
                <div className='panel_section' style={{ marginTop: "65px" }} ref={slider}>
                    {
                        ServicesApi.map((e) => (
                            <div className="panel">
                                <div className="card" >
                                    <h4>
                                        {t(e.title)}
                                    </h4>
                                    <video width="500" autoPlay muted loop style={{ borderRadius: 10 }}>
                                        <source src={e.video} type="video/mp4" />
                                    </video>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default Services