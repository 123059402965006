import React, { useState, useRef, useEffect } from 'react';
import { gsap } from 'gsap';
import { motion } from 'framer-motion';
import MaskedInput from 'react-text-mask';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2'

// Validation Schema with Yup
const validationSchema = Yup.object({
    name: Yup.string().required('İsim Soyisim alanı gereklidir'),
    email: Yup.string().email('Geçersiz e-posta adresi').required('E-posta adresi gereklidir'),
    phone: Yup.string().required('Telefon numarası gereklidir'),
    website: Yup.string().required('Geçersiz URL').required('Website adresi gereklidir'),
    companyname: Yup.string(),
    message: Yup.string().required('Mesaj alanı gereklidir'),
    budget: Yup.string().required('Bütçe aralığı gereklidir'),
    industry: Yup.string().required('Endüstri seçiminiz gereklidir'),
    businesstype: Yup.string().required('Şirket türünü seçmeniz gereklidir'),
    services: Yup.array().min(1, 'En az bir hizmet seçmelisiniz')
});

const ContactForm = () => {
    const { t } = useTranslation()
    const [inputValues, setInputValues] = useState({
        name: '',
        email: '',
        phone: '',
        message: '',
        website: '',
        companyname: '',
        noCompany: false,
        budget: '',
        industry: '',
        businesstype: '',
        services: []
    });
    const labelRefs = useRef([]);

    const handleFocus = (index) => {
        gsap.to(labelRefs.current[index], {
            y: -25,
            x: -15,
            scale: 0.8,
            color: "#ED6F1C",
            duration: 0.1,
            ease: 'power2.out'
        });
    };

    const handleBlur = (index, e) => {
        const { value } = e.target;
        if (value === '') {
            gsap.to(labelRefs.current[index], {
                x: 0,
                y: 0,
                scale: 1,
                color: "rgb(136, 136, 136)",
                duration: 0.3,
                ease: 'power2.out'
            });
        }
        setInputValues({ ...inputValues, [e.target.name]: value });
    };

    useEffect(() => {
        Object.keys(inputValues).forEach((key, index) => {
            if (inputValues[key]) {
                gsap.to(labelRefs.current[index], {
                    y: -25,
                    x: -10,
                    scale: 0.8,
                    color: "rgb(136, 136, 136)",
                    duration: 0.1,
                    ease: 'power2.out'
                });
            }
        });
    }, [inputValues]);

    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        console.log(values)
        try {
            // API isteği yapılıyor
            await axios.post('https://codiatech.com/form_handler.php', values);
            // Başarı mesajı
            Swal.fire({
                title: t("success"),
                text: t("successmessage"),
                icon: "success"
            });
        } catch (error) {
            Swal.fire({
                title: "Bir Hata Oluştu",
                text: "Beklenmedik bir hata meydana geldi. Lütfen sayfayı yenileyip tekrar deneyiniz",
                icon: "error"
            });
        }
        setSubmitting(false);
        resetForm();
    };

    return (
        <Formik
            initialValues={inputValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {({ isSubmitting, setFieldValue }) => (
                <Form>
                    <div className='contactform_content offerform_content'>
                        <div className="contactform_rightside offerform">
                            <div className="row gx-5">
                                <div className="col-lg-4">
                                    <h3 className='text-white mb-5'>{t("offer_form_contact_title")}</h3>
                                    {/* İsim Soyisim */}
                                    <div className="form-group" style={{ position: 'relative', marginBottom: '35px' }}>
                                        <label
                                            ref={el => labelRefs.current[0] = el}
                                            htmlFor="name"
                                            className="contact_label"
                                        >
                                            {t("fullname")}
                                        </label>
                                        <Field
                                            type="text"
                                            id="name"
                                            name="name"
                                            onFocus={() => handleFocus(0)}
                                            onBlur={(e) => handleBlur(0, e)}
                                            className="contact_input"
                                        />
                                        <ErrorMessage name="name" component="div" className="error-message" />
                                    </div>
                                    {/* E-posta Adresi */}
                                    <div className="form-group" style={{ position: 'relative', marginBottom: '35px' }}>
                                        <label
                                            ref={el => labelRefs.current[1] = el}
                                            htmlFor="email"
                                            className="contact_label"
                                        >
                                            {t("email")}
                                        </label>
                                        <Field
                                            type="email"
                                            id="email"
                                            name="email"
                                            onFocus={() => handleFocus(1)}
                                            onBlur={(e) => handleBlur(1, e)}
                                            className="contact_input"
                                        />
                                        <ErrorMessage name="email" component="div" className="error-message" />
                                    </div>
                                    {/* Telefon Numarası */}
                                    <div className="form-group" style={{ position: 'relative', marginBottom: '35px' }}>
                                        <label
                                            ref={el => labelRefs.current[2] = el}
                                            htmlFor="phone"
                                            className="contact_label"
                                        >
                                            {t("phone")}
                                        </label>
                                        <Field
                                            as={MaskedInput}
                                            mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/]}
                                            className="contact_input"
                                            guide={false}
                                            type="tel"
                                            id="phone"
                                            name="phone"
                                            onFocus={() => handleFocus(2)}
                                            onBlur={(e) => handleBlur(2, e)}
                                        />
                                        <ErrorMessage name="phone" component="div" className="error-message" />
                                    </div>
                                    {/* Website Adresi */}
                                    <div className="form-group" style={{ position: 'relative', marginBottom: '35px' }}>
                                        <label
                                            ref={el => labelRefs.current[3] = el}
                                            htmlFor="website"
                                            className="contact_label"
                                        >
                                            {t("offer_form_contact_website")}
                                        </label>
                                        <Field
                                            type="text"
                                            id="website"
                                            name="website"
                                            onFocus={() => handleFocus(3)}
                                            onBlur={(e) => handleBlur(3, e)}
                                            className="contact_input"
                                        />
                                        <ErrorMessage name="website" component="div" className="error-message" />
                                    </div>
                                    {/* Şirket İsmi */}
                                    <div className="form-group" style={{ position: 'relative', marginBottom: '35px' }}>
                                        <label
                                            ref={el => labelRefs.current[4] = el}
                                            htmlFor="companyname"
                                            className={`contact_label ${inputValues.noCompany && "contact_label_disabled"}`}
                                        >
                                            {t("offer_form_contact_company")}
                                        </label>
                                        <Field
                                            type="text"
                                            id="companyname"
                                            name="companyname"
                                            onFocus={() => handleFocus(4)}
                                            onBlur={(e) => handleBlur(4, e)}
                                            className={`contact_input ${inputValues.noCompany && "contact_input_disabled"}`}
                                            disabled={inputValues.noCompany}
                                        />
                                        <div className='mt-3'>
                                            <div className="checkbox-wrapper-32">
                                                <Field type="checkbox" name="noCompany" id="checkbox-32"
                                                    onChange={(e) => {
                                                        const checked = e.target.checked;

                                                        // Formik state'ini güncelle
                                                        setFieldValue('noCompany', checked);

                                                        // local state'i (inputValues) güncelle
                                                        setInputValues({
                                                            ...inputValues,
                                                            noCompany: checked
                                                        });
                                                    }}
                                                />
                                                <label htmlFor="checkbox-32" className='contact_label_checkbox'>
                                                    {t("offer_form_contact_nocompany")}
                                                </label>
                                                <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M 10 10 L 90 90" stroke="rgb(136, 136, 136)" stroke-dasharray="113" stroke-dashoffset="113"></path>
                                                    <path d="M 90 10 L 10 90" stroke="rgb(136, 136, 136)" stroke-dasharray="113" stroke-dashoffset="113"></path>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div>
                                        <h3 className="text-white mb-5">{t("offer_form_project_title")}</h3>
                                        <div>
                                            <h5 className='offerform_title'>{t("offer_form_project_businesstype")}</h5>
                                            <div className='d-flex align-items-center flex-wrap'>
                                                <label>
                                                    <Field type="radio" name="businesstype" value="Başlangıç" />
                                                    <span>{t("offer_form_bt1")}</span>
                                                </label>
                                                <label>
                                                    <Field type="radio" name="businesstype" value="Küçük veya Orta Ölçekli" />
                                                    <span>{t("offer_form_bt3")}</span>
                                                </label>
                                                <label>
                                                    <Field type="radio" name="businesstype" value="Kurumsal İşletme" />
                                                    <span>{t("offer_form_bt4")}</span>
                                                </label>
                                                <label>
                                                    <Field type="radio" name="businesstype" value="Kar Amacı Gütmeyen" />
                                                    <span>{t("offer_form_bt5")}</span>
                                                </label>
                                                <label>
                                                    <Field type="radio" name="businesstype" value="Diğer" />
                                                    <span>{t("offer_form_bt6")}</span>
                                                </label>
                                                <ErrorMessage name="businesstype" component="div" className="error-message" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='mt-5'>
                                        <h5 className='offerform_title'>{t("offer_form_project_industry")}</h5>
                                        <Field as="select" name="industry" className="form-select">
                                            <option value="">{t("offer_form_chooseindustry")}</option>
                                            <option value={t("offerform_industry_item1")}>{t("offerform_industry_item1")}</option>
                                            <option value={t("offerform_industry_item2")}>{t("offerform_industry_item2")}</option>
                                            <option value={t("offerform_industry_item3")}>{t("offerform_industry_item3")}</option>
                                            <option value={t("offerform_industry_item4")}>{t("offerform_industry_item4")}</option>
                                            <option value={t("offerform_industry_item5")}>{t("offerform_industry_item5")}</option>
                                            <option value={t("offerform_industry_item6")}>{t("offerform_industry_item6")}</option>
                                            <option value={t("offerform_industry_item7")}>{t("offerform_industry_item7")}</option>
                                            <option value={t("offerform_industry_item8")}>{t("offerform_industry_item8")}</option>
                                            <option value={t("offerform_industry_item9")}>{t("offerform_industry_item9")}</option>
                                            <option value={t("offerform_industry_item10")}>{t("offerform_industry_item10")}</option>
                                            <option value={t("offerform_industry_item11")}>{t("offerform_industry_item11")}</option>
                                            <option value={t("offerform_industry_item12")}>{t("offerform_industry_item12")}</option>
                                            <option value={t("offerform_industry_item13")}>{t("offerform_industry_item13")}</option>
                                            <option value={t("offerform_industry_item14")}>{t("offerform_industry_item14")}</option>
                                            <option value={t("offerform_industry_item15")}>{t("offerform_industry_item15")}</option>
                                            <option value={t("offerform_industry_item16")}>{t("offerform_industry_item16")}</option>
                                            <option value={t("offerform_industry_item17")}>{t("offerform_industry_item17")}</option>
                                            <option value={t("offerform_industry_item18")}>{t("offerform_industry_item18")}</option>
                                            <option value={t("offerform_industry_item19")}>{t("offerform_industry_item19")}</option>
                                            <option value={t("offerform_industry_item20")}>{t("offerform_industry_item20")}</option>
                                            <option value={t("offerform_industry_item21")}>{t("offerform_industry_item21")}</option>
                                            <option value={t("offerform_industry_item22")}>{t("offerform_industry_item22")}</option>
                                            <option value={t("offerform_industry_item23")}>{t("offerform_industry_item23")}</option>
                                            <option value={t("offerform_industry_item24")}>{t("offerform_industry_item24")}</option>
                                            <option value={t("offerform_industry_item25")}>{t("offerform_industry_item25")}</option>
                                            <option value={t("offerform_industry_item26")}>{t("offerform_industry_item26")}</option>
                                            <option value={t("offerform_industry_item27")}>{t("offerform_industry_item27")}</option>
                                            <option value={t("offerform_industry_item28")}>{t("offerform_industry_item28")}</option>
                                            <option value={t("offerform_industry_item29")}>{t("offerform_industry_item29")}</option>
                                        </Field>
                                        <ErrorMessage name="industry" component="div" className="error-message" />
                                    </div>
                                    <div className='mt-5'>
                                        <div>
                                            <h5 className='offerform_title'>{t("offer_form_project_services")}</h5>
                                            <div className='d-flex align-items-center flex-wrap'>
                                                <label>
                                                    <Field type="checkbox" name="services" value="Web Uygulama" />
                                                    <span>{t("offer_form_sv1")}</span>
                                                </label>
                                                <label>
                                                    <Field type="checkbox" name="services" value="Mobil Uygulama" />
                                                    <span>{t("offer_form_sv2")}</span>
                                                </label>
                                                <label>
                                                    <Field type="checkbox" name="services" value="Masaüstü Uygulama" />
                                                    <span>{t("offer_form_sv3")}</span>
                                                </label>
                                                <label>
                                                    <Field type="checkbox" name="services" value="Sistem Entegrasyonu" />
                                                    <span>{t("offer_form_sv4")}</span>
                                                </label>
                                                <label>
                                                    <Field type="checkbox" name="services" value="Kurumsal Mimari" />
                                                    <span>{t("offer_form_sv5")}</span>
                                                </label>
                                                <label>
                                                    <Field type="checkbox" name="services" value="Partnerlik" />
                                                    <span>{t("offer_form_sv6")}</span>
                                                </label>
                                                <label>
                                                    <Field type="checkbox" name="services" value="Diğer" />
                                                    <span>{t("offer_form_sv7")}</span>
                                                </label>
                                                <label>
                                                    <Field type="checkbox" name="services" value="Emin Değilim" />
                                                    <span>{t("offer_form_sv8")}</span>
                                                </label>
                                            </div>
                                            <ErrorMessage name="services" component="div" className="error-message" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 d-flex flex-column justify-content-between">
                                    <div>
                                        <div className='offerform_lastsection'>
                                            <h5 className='offerform_title'>{t("offer_form_project_budget")}</h5>
                                            <div className='d-flex align-items-center flex-wrap'>
                                                <label>
                                                    <Field type="radio" name="budget" value="0 - 5K $" />
                                                    <span>0 - 5K $</span>
                                                </label>
                                                <label>
                                                    <Field type="radio" name="budget" value="5K - 10K $" />
                                                    <span>5K - 10K $</span>
                                                </label>
                                                <label>
                                                    <Field type="radio" name="budget" value="10K - 25K $" />
                                                    <span>10K - 25K $</span>
                                                </label>
                                                <label>
                                                    <Field type="radio" name="budget" value="25K - 50K $" />
                                                    <span>25K - 50K $</span>
                                                </label>
                                                <label>
                                                    <Field type="radio" name="budget" value="50K - 100K $" />
                                                    <span>50K - 100K $</span>
                                                </label>
                                                <label>
                                                    <Field type="radio" name="budget" value="+ 100K $" />
                                                    <span>+ 100K $</span>
                                                </label>
                                            </div>
                                            <ErrorMessage name="budget" component="div" className="error-message" />
                                        </div>
                                        <div className='mt-5'>
                                            <div className="form-group" style={{ position: 'relative', marginBottom: '35px' }}>
                                                <label
                                                    ref={el => labelRefs.current[5] = el}
                                                    htmlFor="message"
                                                    className="contact_label"
                                                >
                                                    {t("offer_form_project_message")}
                                                </label>
                                                <Field
                                                    as="textarea"
                                                    id="message"
                                                    name="message"
                                                    onFocus={() => handleFocus(5)}
                                                    onBlur={(e) => handleBlur(5, e)}
                                                    className="contact_input contact_text_area"
                                                />
                                                <ErrorMessage name="message" component="div" className="error-message" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='mt-5'>
                                        <div>
                                            <motion.button
                                                type="submit"
                                                className="proposal_btn w-100 py-3"
                                                style={{ fontSize: "18px" }}
                                                initial={{ backgroundPosition: 'left' }}
                                                whileHover={{ backgroundPosition: 'right', transition: { duration: 0.5 } }}
                                                disabled={isSubmitting}
                                            >
                                                {isSubmitting ? 'Gönderiliyor...' : t("offer_form_project_sendform")}
                                            </motion.button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    );
}

export default ContactForm;
