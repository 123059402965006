import Video1 from "../assets/videos/software.mp4"
import Video2 from "../assets/videos/consulting.mp4"
import Video3 from "../assets/videos/integration.mp4"
import Video4 from "../assets/videos/web-mobile-developing.mp4"
import Video5 from "../assets/videos/cloud.mp4"


import ServiceCard1 from "../assets/images/services/custom-sw.png"
import ServiceCard2 from "../assets/images/services/HEm8W5Yn7QHyTKBfR4Rc1SuCJmM.png"
import ServiceCard4 from "../assets/images/services/web-mobile-app.png"
import ServiceCard4Mobile from "../assets/images/services/web-mobile-app-phone.png"
import ServiceCard3 from "../assets/images/services/system.png"
import ServiceCard5 from "../assets/images/services/cloud.png"

import BannerImg1 from "../assets/images/services/bannerimg1.jpg"
import BannerImg2 from "../assets/images/services/bannerimg2.jpg"
import BannerImg3 from "../assets/images/services/bannerimg3.jpg"
import BannerImg4 from "../assets/images/services/bannerimg4.jpg"
import BannerImg5 from "../assets/images/services/bannerimg5.jpg"

import MobileImg1 from "../assets/images/video-to-jpg/software.jpg"
import MobileImg2 from "../assets/images/video-to-jpg/consulting.jpg"
import MobileImg3 from "../assets/images/video-to-jpg/integration.jpg"
import MobileImg4 from "../assets/images/video-to-jpg/web-mobile-developing.jpg"
import MobileImg5 from "../assets/images/video-to-jpg/cloud.jpg"

const Services = [
    {
        id: "2",
        url: "custom-software-development",
        title: "custom_software_development_title",
        servicedetail_text1: "servicedetail_customsoftware_text1",
        servicedetail_text2: "servicedetail_customsoftware_text2",
        servicedetail_title1: "servicedetail_customsoftware_title1",
        servicedetail_title2: "servicedetail_customsoftware_title2",
        servicedetail_title3: "servicedetail_customsoftware_title3",
        servicedetail_title1_text: "servicedetail_customsoftware_title1_text",
        servicedetail_title2_text: "servicedetail_customsoftware_title2_text",
        servicedetail_title3_text: "servicedetail_customsoftware_title3_text",
        servicedetail_appcontent: "servicedetail_customsoftware_appcontent",
        servicedetail_appcontent_title1: "servicedetail_customsoftware_appcontent_title1",
        servicedetail_appcontent_title2: "servicedetail_customsoftware_appcontent_title2",
        servicedetail_appcontent_title3: "servicedetail_customsoftware_appcontent_title3",
        servicedetail_appcontent_title4: "servicedetail_customsoftware_appcontent_title4",
        servicedetail_appcontent_title1_text: "servicedetail_customsoftware_appcontent_title1_text",
        servicedetail_appcontent_title2_text: "servicedetail_customsoftware_appcontent_title2_text",
        servicedetail_appcontent_title3_text: "servicedetail_customsoftware_appcontent_title3_text",
        servicedetail_appcontent_title4_text: "servicedetail_customsoftware_appcontent_title4_text",
        bannerImg: BannerImg1,
        image: ServiceCard1,
        imageSmall: ServiceCard1,
        mobileimg: MobileImg1,
        video: Video1,
        ref: "ref1",
        class: "col-lg-6"
    },
    {
        id: "1",
        url: "consulting-services",
        title: "consulting_services_title",
        servicedetail_text1: "servicedetail_consulting_text1",
        servicedetail_text2: "servicedetail_consulting_text2",
        servicedetail_title1: "servicedetail_consulting_title1",
        servicedetail_title2: "servicedetail_consulting_title2",
        servicedetail_title3: "servicedetail_consulting_title3",
        servicedetail_title1_text: "servicedetail_consulting_title1_text",
        servicedetail_title2_text: "servicedetail_consulting_title2_text",
        servicedetail_title3_text: "servicedetail_consulting_title3_text",
        servicedetail_appcontent: "servicedetail_consulting_appcontent",
        servicedetail_appcontent_title1: "servicedetail_consulting_appcontent_title1",
        servicedetail_appcontent_title2: "servicedetail_consulting_appcontent_title2",
        servicedetail_appcontent_title3: "servicedetail_consulting_appcontent_title3",
        servicedetail_appcontent_title1_text: "servicedetail_consulting_appcontent_title1_text",
        servicedetail_appcontent_title2_text: "servicedetail_consulting_appcontent_title2_text",
        servicedetail_appcontent_title3_text: "servicedetail_consulting_appcontent_title3_text",
        bannerImg: BannerImg2,
        image: ServiceCard2,
        imageSmall: ServiceCard2,
        mobileimg: MobileImg2,
        video: Video2,
        ref: "ref2",
        class: "col-lg-6"
    },
    {
        id: "3",
        url: "system-integration",
        title: "system_integration_title",
        servicedetail_text1: "servicedetail_systemintegration_text1",
        servicedetail_text2: "servicedetail_systemintegration_text2",
        servicedetail_title1: "servicedetail_systemintegration_title1",
        servicedetail_title2: "servicedetail_systemintegration_title2",
        servicedetail_title3: "servicedetail_systemintegration_title3",
        servicedetail_title1_text: "servicedetail_systemintegration_title1_text",
        servicedetail_title2_text: "servicedetail_systemintegration_title2_text",
        servicedetail_title3_text: "servicedetail_systemintegration_title3_text",
        servicedetail_appcontent: "servicedetail_systemintegration_appcontent",
        servicedetail_appcontent_title1: "servicedetail_systemintegration_appcontent_title1",
        servicedetail_appcontent_title2: "servicedetail_systemintegration_appcontent_title2",
        servicedetail_appcontent_title3: "servicedetail_systemintegration_appcontent_title3",
        servicedetail_appcontent_title1_text: "servicedetail_systemintegration_appcontent_title1_text",
        servicedetail_appcontent_title2_text: "servicedetail_systemintegration_appcontent_title2_text",
        servicedetail_appcontent_title3_text: "servicedetail_systemintegration_appcontent_title3_text",
        bannerImg: BannerImg3,
        image: ServiceCard3,
        imageSmall: ServiceCard3,
        mobileimg: MobileImg3,
        video: Video3,
        ref: "ref3",
        class: "col-lg-4 mt-4"
    },
    {
        id: "4",
        url: "web-mobile-application-development",
        title: "web_mobile_app_development_title",
        servicedetail_text1: "servicedetail_webmobile_text1",
        servicedetail_text2: "servicedetail_webmobile_text2",
        servicedetail_title1: "servicedetail_webmobile_title1",
        servicedetail_title2: "servicedetail_webmobile_title2",
        servicedetail_title3: "servicedetail_webmobile_title3",
        servicedetail_title1_text: "servicedetail_webmobile_title1_text",
        servicedetail_title2_text: "servicedetail_webmobile_title2_text",
        servicedetail_title3_text: "servicedetail_webmobile_title3_text",
        servicedetail_appcontent: "servicedetail_webmobile_appcontent",
        servicedetail_appcontent_title1: "servicedetail_webmobile_appcontent_title1",
        servicedetail_appcontent_title2: "servicedetail_webmobile_appcontent_title2",
        servicedetail_appcontent_title3: "servicedetail_webmobile_appcontent_title3",
        servicedetail_appcontent_title1_text: "servicedetail_webmobile_appcontent_title1_text",
        servicedetail_appcontent_title2_text: "servicedetail_webmobile_appcontent_title2_text",
        servicedetail_appcontent_title3_text: "servicedetail_webmobile_appcontent_title3_text",
        bannerImg: BannerImg4,
        image: ServiceCard4,
        imageSmall: ServiceCard4Mobile,
        mobileimg: MobileImg4,
        video: Video4,
        ref: "ref4",
        class: "col-lg-4 mt-4"
    },
    {
        id: "5",
        url: "cloud-services-management",
        title: "cloud_services_management_title",
        servicedetail_text1: "servicedetail_cloud_text1",
        servicedetail_text2: "servicedetail_cloud_text2",
        servicedetail_title1: "servicedetail_cloud_title1",
        servicedetail_title2: "servicedetail_cloud_title2",
        servicedetail_title3: "servicedetail_cloud_title3",
        servicedetail_title1_text: "servicedetail_cloud_title1_text",
        servicedetail_title2_text: "servicedetail_cloud_title2_text",
        servicedetail_title3_text: "servicedetail_cloud_title3_text",
        servicedetail_appcontent: "servicedetail_cloud_appcontent",
        servicedetail_appcontent_title1: "servicedetail_cloud_appcontent_title1",
        servicedetail_appcontent_title2: "servicedetail_cloud_appcontent_title2",
        servicedetail_appcontent_title3: "servicedetail_cloud_appcontent_title3",
        servicedetail_appcontent_title1_text: "servicedetail_cloud_appcontent_title1_text",
        servicedetail_appcontent_title2_text: "servicedetail_cloud_appcontent_title2_text",
        servicedetail_appcontent_title3_text: "servicedetail_cloud_appcontent_title3_text",
        bannerImg: BannerImg5,
        image: ServiceCard5,
        imageSmall: ServiceCard5,
        mobileimg: MobileImg5,
        video: Video5,
        ref: "ref5",
        class: "col-lg-4 mt-4"
    },
    // {
    //     id: "6",
    //     title: "Eğitim ve Destek",
    //     image: ServiceCard1,
    //     video: Video1
    // },
]

export default Services