import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import TechnologyImg from "../assets/images/whyus/technology.svg"
import TeamImg from "../assets/images/whyus/team.svg"
import SolutionsImg from "../assets/images/whyus/solutions.svg"
import QualityImg from "../assets/images/whyus/quality.svg"
import TimeImg from "../assets/images/whyus/time.svg"
import { useTranslation } from 'react-i18next';

gsap.registerPlugin(ScrollTrigger);

const WhyUs = () => {
    const { t } = useTranslation();
    const whyuscontent = useRef(null);
    const card1 = useRef(null);
    const card2 = useRef(null);
    const card3 = useRef(null);
    const card4 = useRef(null);
    const card5 = useRef(null);

    useEffect(() => {
        // GSAP animasyonu: scroll ettikçe bileşen büyüsün
        gsap.fromTo(whyuscontent.current,
            { opacity: 0.1, x: -100 }, // başlangıç boyutu (küçük)
            {
                x: 0,
                opacity: 1,    // hedef boyut (tam)
                scrollTrigger: {
                    trigger: whyuscontent.current,
                    start: 'top bottom', // component ekrana girmeye başladığında
                    end: 'center center',   // component tam ekrana geldiğinde
                    scrub: true,         // yavaş yavaş büyüme efektini sağlayacak
                }
            }
        );
        gsap.fromTo(card1.current,
            { xPercent: -100 }, // başlangıç boyutu (küçük)
            {
                xPercent: 0,    // hedef boyut (tam)
                scrollTrigger: {
                    trigger: card1.current,
                    start: 'top bottom', // component ekrana girmeye başladığında
                    end: 'center center',   // component tam ekrana geldiğinde
                    scrub: true,         // yavaş yavaş büyüme efektini sağlayacak
                }
            }
        );
        gsap.fromTo(card2.current,
            { xPercent: -100 }, // başlangıç boyutu (küçük)
            {
                xPercent: 0,    // hedef boyut (tam)
                scrollTrigger: {
                    trigger: card2.current,
                    start: 'top bottom', // component ekrana girmeye başladığında
                    end: 'center center',   // component tam ekrana geldiğinde
                    scrub: true,         // yavaş yavaş büyüme efektini sağlayacak
                }
            }
        );
        gsap.fromTo(card3.current,
            { xPercent: -100 }, // başlangıç boyutu (küçük)
            {
                xPercent: 0,    // hedef boyut (tam)
                scrollTrigger: {
                    trigger: card3.current,
                    start: 'top bottom', // component ekrana girmeye başladığında
                    end: 'center center',   // component tam ekrana geldiğinde
                    scrub: true,         // yavaş yavaş büyüme efektini sağlayacak
                }
            }
        );
        gsap.fromTo(card4.current,
            { xPercent: -100 }, // başlangıç boyutu (küçük)
            {
                xPercent: 0,    // hedef boyut (tam)
                scrollTrigger: {
                    trigger: card4.current,
                    start: 'top bottom', // component ekrana girmeye başladığında
                    end: 'center center',   // component tam ekrana geldiğinde
                    scrub: true,         // yavaş yavaş büyüme efektini sağlayacak
                }
            }
        );
        gsap.fromTo(card5.current,
            { xPercent: -100 }, // başlangıç boyutu (küçük)
            {
                xPercent: 0,    // hedef boyut (tam)
                scrollTrigger: {
                    trigger: card5.current,
                    start: 'top bottom', // component ekrana girmeye başladığında
                    end: 'center center',   // component tam ekrana geldiğinde
                    scrub: true,         // yavaş yavaş büyüme efektini sağlayacak
                }
            }
        );
    }, []);

    return (
        <div className='whyus_content'>
            <div className="container" ref={whyuscontent}>
                <h3 className='homepage_section_title text-center'>
                    {t("homepage_whyus_title")}
                </h3>
                <div className="whyus_card_content">
                    <div className="row" ref={card1}>
                        <div className="col-lg-7">
                            <div className="whyus_card">
                                <div className="row">
                                    <div className="col-lg-8">
                                        <h5>{t("homepage_whyus_item1_title")}</h5>
                                        <p className="mb-0">
                                            {t("homepage_whyus_item1_text")}
                                        </p>
                                    </div>
                                    <div className="col-lg-4 text-end">
                                        <img src={TechnologyImg} alt="" width="90%" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center" ref={card2}>
                        <div className="col-lg-7">
                            <div className="whyus_card">
                                <div className="row">
                                    <div className="col-lg-8">
                                        <h5>{t("homepage_whyus_item2_title")}</h5>
                                        <p className="mb-0">
                                            {t("homepage_whyus_item2_text")}
                                        </p>
                                    </div>
                                    <div className="col-lg-4 text-end">
                                        <img src={TeamImg} alt="" width="90%" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-end" ref={card3}>
                        <div className="col-lg-7">
                            <div className="whyus_card">
                                <div className="row">
                                    <div className="col-lg-8">
                                        <h5>{t("homepage_whyus_item3_title")}</h5>
                                        <p className="mb-0">
                                            {t("homepage_whyus_item3_text")}
                                        </p>
                                    </div>
                                    <div className="col-lg-4">
                                        <img src={SolutionsImg} alt="" width="90%" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center" ref={card4}>
                        <div className="col-lg-7">
                            <div className="whyus_card">
                                <div className="row">
                                    <div className="col-lg-8">
                                        <h5>{t("homepage_whyus_item4_title")}</h5>
                                        <p className="mb-0">
                                            {t("homepage_whyus_item4_text")}
                                        </p>
                                    </div>
                                    <div className="col-lg-4">
                                        <img src={QualityImg} alt="" width="90%" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row" ref={card5}>
                        <div className="col-lg-7">
                            <div className="whyus_card">
                                <div className="row">
                                    <div className="col-lg-8">
                                        <h5>{t("homepage_whyus_item5_title")}</h5>
                                        <p className="mb-0">
                                            {t("homepage_whyus_item5_text")}
                                        </p>
                                    </div>
                                    <div className="col-lg-4">
                                        <img src={TimeImg} alt="" width="90%" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WhyUs