import React from 'react'
import ServicesApi from "../api/Services"
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const ServiceDetail = () => {
    const { t } = useTranslation()
    const { title } = useParams();
    const ServiceDetails = ServicesApi.find(item => item.url === title)
    console.log(ServiceDetails);
    return (
        <div className='subpage_servicedetail_content'>
            <div className='subpage_servicedetail_banner' style={{ backgroundImage: `url(${ServiceDetails.bannerImg})` }}></div>
            <div className="container subpage_servicedetail_bannerbottom">
                <h1 className='subpage_servicedetail_title mb-3'>{t(ServiceDetails.title)}</h1>
                <div className="row">
                    <div className="col-lg-8">
                        <h5 className='subpage_servicedetail_paragraph'>
                            {t(ServiceDetails.servicedetail_text1)} <br /><br /> {t(ServiceDetails.servicedetail_text2)}
                        </h5>
                    </div>
                </div>
                <div className="row gx-5 mt-5">
                    <div className="col-lg-4">
                        <h3 className='subpage_servicedetail_subtitles mb-1'>{t(ServiceDetails.servicedetail_title1)}</h3>
                        <p className='subpage_servicedetail_descriptions'>{t(ServiceDetails.servicedetail_title1_text)}</p>
                    </div>
                    <div className="col-lg-4 mt-lg-0 mt-3">
                        <h3 className='subpage_servicedetail_subtitles mb-1'>{t(ServiceDetails.servicedetail_title2)}</h3>
                        <p className='subpage_servicedetail_descriptions'>{t(ServiceDetails.servicedetail_title2_text)}</p>
                    </div>
                    <div className="col-lg-4 mt-lg-0 mt-3">
                        <h3 className='subpage_servicedetail_subtitles mb-1'>{t(ServiceDetails.servicedetail_title3)}</h3>
                        <p className='subpage_servicedetail_descriptions'>{t(ServiceDetails.servicedetail_title3_text)}</p>
                    </div>
                </div>
            </div>
            <div className="subpage_servicedetail_contentarea">
                <div className="container">
                    <div className="row gx-5">
                        <div className="col-lg-6">
                            <img src={ServiceDetails.bannerImg} alt="" width="100%" />
                        </div>
                        <div className="col-lg-6 mt-lg-0 mt-4">
                            <h3 className='mb-lg-5 mb-4'>{t(ServiceDetails.servicedetail_appcontent)}</h3>
                            <div className='subpage_servicedetail_contentarea_item'>
                                <h5>{t(ServiceDetails.servicedetail_appcontent_title1)}</h5>
                                <p className="mb-0">
                                    {t(ServiceDetails.servicedetail_appcontent_title1_text)}
                                </p>
                            </div>
                            <div className='subpage_servicedetail_contentarea_item'>
                                <h5>{t(ServiceDetails.servicedetail_appcontent_title2)}</h5>
                                <p className="mb-0">
                                    {t(ServiceDetails.servicedetail_appcontent_title2_text)}
                                </p>
                            </div>
                            <div className='subpage_servicedetail_contentarea_item'>
                                <h5>{t(ServiceDetails.servicedetail_appcontent_title3)}</h5>
                                <p className="mb-0">
                                    {t(ServiceDetails.servicedetail_appcontent_title3_text)}
                                </p>
                            </div>
                            <div>
                                <h5>{t(ServiceDetails.servicedetail_appcontent_title4)}</h5>
                                <p className="mb-0">
                                    {t(ServiceDetails.servicedetail_appcontent_title4_text)}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="subpage_tools_technologies_content">
                <div className="container">
                    <h3>Kullanılan Teknolojiler</h3>
                    <div className="row mt-5">
                        <div className="col-lg-6">
                            <h5>Frontend</h5>
                            <div className='tools_content mt-3'>
                                <div className='tools_content_item'>
                                    React js
                                </div>
                                <div className='tools_content_item'>
                                    Javascript
                                </div>
                                <div className='tools_content_item'>
                                    Angular
                                </div>
                                <div className='tools_content_item'>
                                    Vue js
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <h5>Backend</h5>
                            <div className='tools_content mt-3'>
                                <div className='tools_content_item'>
                                    C#
                                </div>
                                <div className='tools_content_item'>
                                    Python
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 mt-5">
                            <h5>DevOps</h5>
                            <div className='tools_content mt-3'>
                                <div className='tools_content_item'>
                                    C#
                                </div>
                                <div className='tools_content_item'>
                                    Python
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    )
}

export default ServiceDetail