import React from 'react'
import Slider from "react-slick";

const BannerCarousel = () => {
    const settings = {
        dots: false, // Noktalar varsa, sürekli kaydırma biraz karmaşık olabilir
        arrows: false,
        infinite: true, // Sonsuz döngü
        speed: 5000, // Geçiş hızı (milisaniye cinsinden)
        slidesToShow: 5, // Ekranda kaç slide görünecek
        slidesToScroll: 1, // Bir defada kaç slide kaydırılacak
        autoplay: true, // Otomatik kaydırma
        autoplaySpeed: 0, // Otomatik kaydırma süresi (0, sürekli kaydırma anlamına gelir)
        cssEase: 'linear', // Geçiş efekti (lineer, yavaşlama olmadan)
        pauseOnHover: false, // Üzerine gelindiğinde duraklamasın
    };
    return (
        <div className="slider-container">
            <Slider {...settings}>
                <div>
                    <div className='slider-item'>
                        <img src="https://framerusercontent.com/images/PrYL2FAGDd3rOBRJ0l9cJa32AQ.png" alt="" width="100%" />
                    </div>
                </div>
                <div>
                    <div className='slider-item'>
                        <img src="https://framerusercontent.com/images/PrYL2FAGDd3rOBRJ0l9cJa32AQ.png" alt="" width="100%" />
                    </div>
                </div>
                <div>
                    <div className='slider-item'>
                        <img src="https://framerusercontent.com/images/PrYL2FAGDd3rOBRJ0l9cJa32AQ.png" alt="" width="100%" />
                    </div>
                </div>
                <div>
                    <div className='slider-item'>
                        <img src="https://framerusercontent.com/images/PrYL2FAGDd3rOBRJ0l9cJa32AQ.png" alt="" width="100%" />
                    </div>
                </div>
                <div>
                    <div className='slider-item'>
                        <img src="https://framerusercontent.com/images/PrYL2FAGDd3rOBRJ0l9cJa32AQ.png" alt="" width="100%" />
                    </div>
                </div>
                <div>
                    <div className='slider-item'>
                        <img src="https://framerusercontent.com/images/PrYL2FAGDd3rOBRJ0l9cJa32AQ.png" alt="" width="100%" />
                    </div>
                </div>
            </Slider>
        </div>
    )
}

export default BannerCarousel