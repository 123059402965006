import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Select from "react-select";
import { LANGUAGES } from "../../constants";

const LanguageSwitcher = () => {
    const { i18n } = useTranslation();

    // const changeLanguage = (lang) => {
    //     i18n.changeLanguage(lang);
    // };
    const [selectedLanguage, setSelectedLanguage] = useState(
        JSON.parse(localStorage.getItem("lang"))
            ? JSON.parse(localStorage.getItem("lang"))
            : "tr"
    );

    const onChangeLang = (e) => {
        const lang_code = e.code;
        i18n.changeLanguage(lang_code);
        setSelectedLanguage(e.code);
    };

    useEffect(() => {
        localStorage.setItem("lang", JSON.stringify(selectedLanguage));
    }, []);

    useEffect(() => {
        localStorage.setItem("lang", JSON.stringify(selectedLanguage));
    }, [selectedLanguage]);

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            background: "transparent",
            border: "none",
            color: "#000",
            cursor: "pointer",
            fontWeight: 500,
            fontSize: 15
        }),
        menu: (provided, state) => ({
            ...provided,
            background: "none",
            color: "#fff",
            width: "43px"
        }),
        option: (provided, state) => ({
            ...provided,
            background: state.isFocused ? "#f0f0f0" : "white",
            color: "#000",
            "&:hover": {
                backgroundColor: "#eb6e1d"
            }
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: "#EB6E1D",
            fontWeight: 600
        }),
        indicatorSeparator: (provided, state) => ({
            display: "none",
        }),
        dropdownIndicator: (provided, state) => ({
            display: "none",
        }),
    };

    const customStylesMobile = {
        control: (provided, state) => ({
            ...provided,
            background: "none",
            border: "none",
            color: "#fff",
            marginBottom: "20px",
        }),
        menu: (provided, state) => ({
            ...provided,
            background: "none",
            color: "#fff",
            width: "fit-content",
        }),
        option: (provided, state) => ({
            ...provided,
            background: state.isFocused ? "#f0f0f0" : "white",
            color: "#000",
            padding: "15px",
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: "#fff",
            fontSize: "20px",
        }),
        indicatorSeparator: (provided, state) => ({
            display: "none",
        }),
        dropdownIndicator: (provided, state) => ({
            display: "none",
        }),
    };

    return (
        <div>
            {/* <button className='text-white' onClick={() => changeLanguage('en')}>English</button>
            <button className='text-white' onClick={() => changeLanguage('tr')}>Türkçe</button> */}
            <Select
                className="language-select-box"
                defaultValue={
                    LANGUAGES.find((lang) => lang.code === selectedLanguage)
                        ? LANGUAGES.find((lang) => lang.code === selectedLanguage)
                        : LANGUAGES.find((lang) => lang.code === "en")
                }
                // value={LANGUAGES.find((lang) => lang.code === selectedLanguage)}
                onChange={onChangeLang}
                styles={customStyles}
                isSearchable={false}
                options={LANGUAGES}
                getOptionLabel={(option) => (
                    <div className="d-flex align-items-center">
                        {/* <img
                            src={option.flag}
                            alt={option.label}
                            width="30px"
                            className="me-2"
                        /> */}
                        {option.label}
                    </div>
                )}
            />
        </div>
    );
};

export default LanguageSwitcher;
