import i18n from "i18next";
import i18nBackend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

// const getCurrentHost =
// 	import.meta.env.MODE === "development"
// 		? "http://localhost:5173"
// 		: "LINK TO PROD";

const storedLang = localStorage.getItem("lang")
console.log(storedLang)

const url =
    window.location.host === "localhost:3000"
        ? "localhost:3000"
        : window.location.host;

i18n
    .use(i18nBackend)
    .use(initReactI18next)
    .init({
        fallbackLng: "tr",
        lng: storedLang ? storedLang : "en",
        interpolation: {
            escapeValue: false,
        },
        backend: {
            loadPath: window.location.hostname.includes("localhost")
                ? `http://localhost:3000/locales/{{lng}}/translation.json`
                : `https://${url}/locales/{{lng}}/translation.json`,
        },
    });

export default i18n;
