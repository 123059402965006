import React, { useRef, useLayoutEffect, useEffect, useState } from 'react'
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import ServicesApi from "../api/Services"
import { useTranslation } from 'react-i18next';
import Slider from "react-slick";

gsap.registerPlugin(ScrollTrigger);

const Services = () => {
    const { t } = useTranslation()
    const component = useRef();

    const settings = {
        dots: true,
        infinite: true,
        arrows: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return (
        <div className='services_content' ref={component}>
            <div className="container">
                <h3 className='homepage_section_title mb-5'>
                    {t("homepage_services_title")}
                </h3>
                <div className="services_mobile_content">
                    <Slider {...settings}>
                        {
                            ServicesApi.map((e) => (
                                <div className="panel">
                                    <div className="card" >
                                        <h4>
                                            {t(e.title)}
                                        </h4>
                                        <img src={e.mobileimg} alt="thumb-img" />
                                    </div>
                                </div>
                            ))
                        }
                    </Slider>
                </div>
            </div>
        </div>
    )
}

export default Services